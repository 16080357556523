import rules from "../../../utils/rules";
import { mapGetters } from "vuex";
export default {
  name: "login-redirect",
  components: {},
  props: [],
  data() {
    return {
      valid: true,
      rules: rules,
      username: "",
      password: "",
      loading: false,
      isError: false,
      errorText: "",
      hidePassword: true
    };
  },
  computed: {
    ...mapGetters(["setErrorMessageLogin"]),
  },
  mounted() {},
  methods: {
    backToLoginPage(){
      this.$router.push("/signin");
    }
    // async login() {
    //   this.loading = true;
    //   if (this.$refs.form.validate()) {
    //     let result = await this.$store.dispatch("LoginByEmail", {
    //       username: this.username,
    //       password: this.password
    //     });
    //     if (result) {
    //       this.loading = false;
    //       await this.$router.push(this.$route.query.redirect || "/");
    //     } else {
    //       this.loading = false;
    //       this.isError = true;
    //       this.errorText = "Invalid Username or Password, Please try again";
    //       setTimeout(() => (this.isError = false), 2000);
    //     }
    //   } else {
    //     this.loading = false;
    //   }
    // }
  }
};
